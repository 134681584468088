import React from 'react';
import TierOne from "./TierOne";
import TierTwo from "./TierTwo";
import TierThree from "./TierThree";
import './index.css';
import {injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="vitamin-levels">
        <div
            className="vitamin-levels__header"
            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.levels.header'})}}
        />
        <TierOne />
        <TierTwo />
        <TierThree />
    </div>
));
