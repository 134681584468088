import React from 'react';
import './TierElement.css';

export default ({img, header, text}) => (
    <div className="vitamin-levels-tier-element">
        <img
            className="vitamin-levels-tier-element__image"
            src={img}
        />
        <div className="vitamin-levels-tier-element__header">
            {header}
        </div>
        <div className="vitamin-levels-tier-element__text">
            {text}
        </div>
    </div>
);
