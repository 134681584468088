import React from 'react';
import cx from 'classnames';
import './Item.css';

export default ({iconUrl, large, children}) => {
    const classes = cx({
        'vitamin-sources-item': true,
        'vitamin-sources-item--large': large
    });
    return (
        <div className={classes}>
            <div className="vitamin-sources-item__img">
                <img
                    alt=""
                    src={iconUrl}
                />
            </div>
            <div className="vitamin-sources-item__text">
                {children}
            </div>
        </div>
    );
}