import React from 'react';
import TierElement from "./TierElement";
import './TierThree.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div>
        <div className="vitamin-levels-tier-three vitamin-levels-tier-three-desktop">
            <TierElement
                img="/static/svg/vitamin-levels-worker.svg"
                header={<FormattedMessage id="vitamin.levels.tier.three.left.header" />}
                text={<FormattedMessage id="vitamin.levels.tier.three.left.text" />}
            />
            <TierElement
                img="/static/svg/vitamin-levels-yoga.svg"
                header={<FormattedMessage id="vitamin.levels.tier.three.center.header" />}
                text={<FormattedMessage id="vitamin.levels.tier.three.center.text" />}
            />
            <TierElement
                img="/static/svg/vitamin-levels-guy.svg"
                header={<FormattedMessage id="vitamin.levels.tier.three.right.header" />}
                text={<FormattedMessage id="vitamin.levels.tier.three.right.text" />}
            />
        </div>
        <div className="vitamin-levels-tier-three-mobile">
            <div className="vitamin-levels-tier-three">
                <TierElement
                    img="/static/svg/vitamin-levels-worker.svg"
                    header={<FormattedMessage id="vitamin.levels.tier.three.left.header" />}
                    text={<FormattedMessage id="vitamin.levels.tier.three.left.text" />}
                />
                <TierElement
                    img="/static/svg/vitamin-levels-yoga.svg"
                    header={<FormattedMessage id="vitamin.levels.tier.three.center.header" />}
                    text={<FormattedMessage id="vitamin.levels.tier.three.center.text" />}
                />
            </div>
            <div className="vitamin-levels-tier-three vitamin-levels-tier-three-lower">
                <TierElement
                    img="/static/svg/vitamin-levels-guy.svg"
                    header={<FormattedMessage id="vitamin.levels.tier.three.right.header" />}
                    text={<FormattedMessage id="vitamin.levels.tier.three.right.text" />}
                />
            </div>
        </div>
    </div>
);
