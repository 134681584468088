import React from 'react';
import TierElement from "./TierElement";
import './TierTwo.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="vitamin-levels-tier-two">
        <TierElement
            img="/static/svg/vitamin-levels-bone.svg"
            header={<FormattedMessage id="vitamin.levels.tier.two.left.header" />}
            text={<FormattedMessage id="vitamin.levels.tier.two.left.text" />}
        />
        <TierElement
            img="/static/svg/vitamin-levels-person.svg"
            header={<FormattedMessage id="vitamin.levels.tier.two.right.header" />}
            text={<FormattedMessage id="vitamin.levels.tier.two.right.text" />}
        />
    </div>
);
