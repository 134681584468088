import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const top = cx({
        'vitamin-who__content-text': true,
        'vitamin-who__content-text-top': true,
        'vitamin-who__content-text-ru': locale === 'ru'
    });
    const topRight = cx({
        'vitamin-who__content-text': true,
        'vitamin-who__content-text-top-right': true,
        'vitamin-who__content-text-ru': locale === 'ru'
    });
    const bottomLeft = cx({
        'vitamin-who__content-text': true,
        'vitamin-who__content-text-bottom-left': true,
        'vitamin-who__content-text-bottom-left-ru': locale === 'ru',
        'vitamin-who__content-text-ru': locale === 'ru'
    });
    const bottomRight = cx({
        'vitamin-who__content-text': true,
        'vitamin-who__content-text-bottom-right': true,
        'vitamin-who__content-text-ru': locale === 'ru'
    });
    const bottom = cx({
        'vitamin-who__content-text': true,
        'vitamin-who__content-text-bottom': true,
        'vitamin-who__content-text-bottom-ru': locale === 'ru',
        'vitamin-who__content-text-ru': locale === 'ru'
    });
    return (
        <div className="vitamin-who">
            <div
                className="vitamin-who__header"
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.who.header'})}}
            />
            <div className="vitamin-who__content">
                <img
                    className="vitamin-who__content-image"
                    src="/static/images/vitamin-who.png"
                />
                <img
                    className="vitamin-who__content-image-mobile"
                    src="/static/images/vitamin-who-mobile.png"
                />
                <div className={top}>
                    <FormattedMessage id="vitamin.who.text.top" />
                </div>
                <div className={topRight}>
                    <FormattedMessage id="vitamin.who.text.top.right" />
                </div>
                <div className={bottomLeft}>
                    <FormattedMessage id="vitamin.who.text.bottom.left" />
                </div>
                <div className={bottomRight}>
                    <FormattedMessage id="vitamin.who.text.bottom.right" />
                </div>
                <div className={bottom}>
                    <FormattedMessage id="vitamin.who.text.bottom" />
                </div>
            </div>
        </div>
    )
});
