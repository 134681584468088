import React from 'react';
import './TierOne.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="vitamin-levels-tier-one">
        <div className="vitamin-levels-tier-one-desktop">
            <div className="vitamin-levels-tier-one__text">
                <span><FormattedMessage id="vitamin.levels.tier.one.left.header" /></span>
                <FormattedMessage id="vitamin.levels.tier.one.left.text" />
            </div>
            <img
                className="vitamin-levels-tier-one__img"
                src="/static/svg/vitamin-levels-woman.svg"
            />
            <div className="vitamin-levels-tier-one__text">
                <span><FormattedMessage id="vitamin.levels.tier.one.right.header"/></span>
                <FormattedMessage id="vitamin.levels.tier.one.right.text" />
            </div>
        </div>
        <div className="vitamin-levels-tier-one-mobile">
            <img
                className="vitamin-levels-tier-one__img"
                src="/static/svg/vitamin-levels-woman.svg"
            />
            <div className="vitamin-levels-tier-one__lower">
                <div className="vitamin-levels-tier-one__text">
                    <span><FormattedMessage id="vitamin.levels.tier.one.left.header" /></span>
                    <FormattedMessage id="vitamin.levels.tier.one.left.text" />
                </div>
                <div className="vitamin-levels-tier-one__text">
                    <span><FormattedMessage id="vitamin.levels.tier.one.right.header"/></span>
                    <FormattedMessage id="vitamin.levels.tier.one.right.text" />
                </div>
            </div>
        </div>
    </div>
);
