import React from 'react';
import Item from './Item';
import './index.css';
import {injectIntl, FormattedMessage} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="vitamin-deficiency">
        <div
            className="vitamin-deficiency__header"
            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.deficiency.header'})}}
        />
        <div className="vitamin-deficiency__items">
            <Item
                large
                url="/static/images/vitamin-deficiency-runner.png"
                header={<span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.deficiency.left.header'})}}/>}
                text={<span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.deficiency.left.text'})}}/>}
            />
            <Item
                url="/static/images/vitamin-deficiency-reader.png"
                header={<FormattedMessage id="vitamin.deficiency.center.header" />}
                text={<span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.deficiency.center.text'})}}/>}
            />
            <Item
                url="/static/images/vitamin-deficiency-sleeper.png"
                header={<FormattedMessage id="vitamin.deficiency.right.header" />}
                text={<span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.deficiency.right.text'})}}/>}
            />
        </div>
    </div>
));
