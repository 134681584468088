import React from 'react';
import './index.css';
import cx from 'classnames';
import {injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'vitamin-facts-content__text': true,
        'vitamin-facts-content__text-ru': locale === 'ru'
    });
    return (
        <div className="vitamin-facts">
            <div className="vitamin-facts-right">
                <div className="vitamin-facts-content">
                    <div
                        className="vitamin-facts-content__header"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.facts.header'})}}
                    />
                    <div className="vitamin-facts-content__item">
                        <img
                            src="/static/svg/vitamin-fact-point.svg"
                        />
                        <div
                            className={classes}
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.facts.point.first'})}}
                        />
                    </div>
                    <div className="vitamin-facts-content__item">
                        <img
                            src="/static/svg/vitamin-fact-point.svg"
                        />
                        <div
                            className={classes}
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.facts.point.second'})}}
                        />
                    </div>
                    <div className="vitamin-facts-content__item">
                        <img
                            src="/static/svg/vitamin-fact-point.svg"
                        />
                        <div
                            className={classes}
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.facts.point.third'})}}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
});