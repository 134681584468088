import React from 'react';
import BuyNowButton from '../../common/buy-now-button';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'vitamin-suggestions__header': true,
        'vitamin-suggestions__header-ru': locale === 'ru'
    })
    const text = cx({
        'vitamin-suggestions__text': true,
        'vitamin-suggestions__text-ru': locale === 'ru'
    })
    const img = cx({
        'vitamin-suggestions__image': true,
        'vitamin-suggestions__image-ru': locale === 'ru'
    })
    return (
        <div className="vitamin-suggestions">
            <div
                className={classes}
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.suggestions.header'})}}
            />
            <div className="vitamin-suggestions__content">
                <div className="vitamin-suggestions__image-wrapper">
                    <img
                        className={img}
                        src="/static/images/suggestions.png"
                    />
                </div>
                <img
                    className="vitamin-suggestions__bottles"
                    src="/static/images/top-banner-bottles.png"
                />
                <span className="vitamin-suggestions__button">
                    <BuyNowButton large />
                </span>
                <div
                    className={text}
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.suggestions.text'})}}
                />
            </div>
        </div>
    )
});
