import React from 'react';
import HeaderText from "./HeaderText";
import Item from "./Item";
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div id="about-vitamin" className="about-vitamin">
        <HeaderText/>
        <div className="about-vitamin-items">
            <Item
                logo="/static/svg/about-zen.svg"
                header={<FormattedMessage id="about.vitamin.left.item.header"/>}
            >
                <div
                    className="about-vitamin-item-left-text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'about.vitamin.left.item.text'})}}
                />
            </Item>
            <Item
                logo="/static/svg/about-shield.svg"
                header={<FormattedMessage id="about.vitamin.center.item.header" />}
            >
                <div
                    className="about-vitamin-item-center-text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'about.vitamin.center.item.text'})}}
                />
            </Item>
            <Item
                logo="/static/svg/about-hand.svg"
                header={<FormattedMessage id="about.vitamin.right.item.header" />}
            >
                <FormattedMessage id="about.vitamin.right.item.text" />
            </Item>
        </div>
    </div>
));