import React, {Component} from 'react';
import {IntlProvider} from 'react-intl';
import Header from './components/sections/header'
import TopBanner from './components/sections/top-banner';
import AboutVitamin from './components/sections/about-vitamin';
import VitaminFacts from './components/sections/vitamin-facts';
import VitaminSources from './components/sections/vitamin-sources';
import VitaminEstonia from './components/sections/vitamin-estonia';
import VitaminLevels from './components/sections/vitamin-levels';
import VitaminDeficiency from './components/sections/vitamin-deficiency';
import MiddleBuyNow from './components/sections/middle-buy-now';
import VitaminForWomen from './components/sections/vitamin-for-women';
import VitaminAdvice from './components/sections/vitamin-advice';
import VitaminWho from './components/sections/vitamin-who';
import WhyCapsule from './components/sections/why-capsule';
import VitaminSuggestions from './components/sections/vitamin-suggestions';
import Specialists from './components/sections/specialists';
import Share from './components/sections/share';
import Footer from './components/sections/footer';
import messages from "./locales/messages";

class Page extends Component {
  render() {
    const { locale } = this.props;
    return (
      <IntlProvider onError={() => {}} locale={locale} messages={messages[locale]}>
        <div className="page">
          <Header locale={locale} />
          <TopBanner/>
          <AboutVitamin/>
          <VitaminFacts locale={locale} />
          <VitaminSources/>
          <VitaminEstonia locale={locale} />
          <VitaminLevels/>
          <VitaminDeficiency/>
          <MiddleBuyNow/>
          <VitaminForWomen locale={locale} />
          <VitaminAdvice/>
          <VitaminWho locale={locale} />
          <WhyCapsule locale={locale} />
          <VitaminSuggestions locale={locale} />
          {/*<Specialists/>*/}
          <Share/>
          <Footer/>
        </div>
      </IntlProvider>
    );
  }
}

export default Page;
