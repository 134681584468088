import React from 'react';
import './Item.css';

export default ({logo, header, children}) => (
    <div className="about-vitamin-item">
        <img
            src={logo}
        />
        <div>
            {header}
        </div>
        <div>
            {children}
        </div>
    </div>
);