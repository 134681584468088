import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'why-capsule-text': true,
        'why-capsule-text-ru': locale === 'ru'
    })
    return (
        <div className="why-capsule">
            <div className="why-capsule__header">
                <FormattedMessage id="why.capsule.header" />
            </div>
            <div className="why-capsule-wrapper">
                <div className="why-capsule-capsules">
                    <img
                        className="why-capsule-capsules__image"
                        src="/static/images/pill.png"
                    />
                    <div className={classes}>
                        <div className="why-capsule-text__header">
                            <FormattedMessage id="why.capsule.left.header" />
                        </div>
                        <div>
                            <p
                                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'why.capsule.left.text.top'})}}
                            />
                            <span
                                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'why.capsule.left.text.bottom'})}}
                            />
                        </div>
                    </div>
                </div>
                <div className="why-capsule-grapes">
                    <img
                        className="why-capsule-grapes__image"
                        src="/static/images/grape.png"
                    />
                    <div className={classes}>
                        <div className="why-capsule-text__header">
                            <FormattedMessage id="why.capsule.right.header" />
                        </div>
                        <div>
                            <p><FormattedMessage id="why.capsule.right.text.top" /></p>
                            <FormattedMessage id="why.capsule.right.text.bottom" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});
