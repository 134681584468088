import React from 'react';
import './HeaderText.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="about-vitamin-header-text">
        <FormattedMessage id="about.vitamin.header" />
    </div>
);

