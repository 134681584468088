import React from 'react';
import Item from './Item';
import './index.css';
import {injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="vitamin-sources">
        <div
            className="vitamin-sources__header"
            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.sources.header'})}}
        />
        <div className="vitamin-sources__items">
            <Item iconUrl="/static/svg/sources-mushrooms.svg">
                <span
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.sources.left'})}}
                />
            </Item>
            <Item large iconUrl="/static/svg/sources-sun.svg">
                <span
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.sources.center'})}}
                />
            </Item>
            <Item large iconUrl="/static/svg/sources-fish.svg">
                <span
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.sources.right'})}}
                />
            </Item>
        </div>
    </div>
));