import React from 'react';
import './index.css';
import {FormattedMessage} from "react-intl";

export default () => {
    return (
        <div className="top-banner">
            <img
                src="/static/images/top-banner.png"
                alt=""
                className="top-banner__image"
            />
            <img
                src="/static/images/top-banner-grass.png"
                alt=""
                className="top-banner__image-grass"
            />
            <img
                src="/static/images/top-banner-bottles.png"
                alt=""
                className="top-banner__image-bottles"
            />
            <div className="top-banner__text">
                <FormattedMessage id="top.banner.text" />
            </div>
        </div>
    );
}
