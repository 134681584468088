import React from 'react';
import './index.css';
import cx from 'classnames';
import {injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'vitamin-estonia__text': true,
        'vitamin-estonia__text-ru': locale === 'ru'
    });
    const classes_wrap = cx({
        'vitamin-estonia': true,
        'vitamin-estonia-ru': locale === 'ru'
    });
    return (
        <div id="vitamin-estonia" className={classes_wrap}>
            <div className={classes}>
                <div
                    className="vitamin-estonia__text-header"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.estonia.header'})}}
                />
                <div className="vitamin-estonia__text-content">
                    <div className="vitamin-estonia__text-content-item">
                        <img
                            src="/static/svg/vitamin-estonia-point.svg"
                        />
                        <div
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.estonia.text.first'})}}
                        />
                    </div>
                    <br />
                    <div className="vitamin-estonia__text-content-item">
                        <img
                            src="/static/svg/vitamin-estonia-point.svg"
                        />
                        <div
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.estonia.text.second'})}}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
});
