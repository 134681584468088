import React from 'react';
import './index.css';
import {injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="vitamin-advice">
        <div
            className="vitamin-advice__header"
            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.advice.header'})}}
        />
        <div className="vitamin-advice-container">
            <div className="vitamin-advice__items">
                <div className="vitamin-advice__items-item">
                    <img
                        src="/static/svg/vitamin-advice-point.svg"
                    />
                    <span
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.advice.first.point'})}}
                    />
                </div>
                <div className="vitamin-advice__items-item">
                    <img
                        src="/static/svg/vitamin-advice-point.svg"
                    />
                    <span
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.advice.second.point'})}}
                    />
                </div>
                <div className="vitamin-advice__items-item">
                    <img
                        src="/static/svg/vitamin-advice-point.svg"
                    />
                    <span
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.advice.third.point'})}}
                    />
                </div>
            </div>
        </div>
    </div>
));
